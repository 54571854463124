import React from "react";
import { Link } from "react-router-dom";
import "../App.css";
import HomeImage from "../images/Homepage/no-fly-tipping.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Header from "../components/Header";
import AwardsLogo from "../images/awards-logo.jpg"; // Ensure the correct path

function Home() {
  return (
    <div>
      <Header />
      <div className="custom-container">
        <div>
        <div className="homepage-container">
      {/* Left Column */}
      <div className="homepage-text-column">
        <h1>The SkipSpace Story: Revolutionising the Fight Against Fly-Tipping</h1>
        <h2>The Problem: A National Crisis of Fly-Tipping</h2>
        <p>
          Fly-tipping has become a scourge across the country, causing significant harm to the environment, public health, and local communities. The current system offers no reasonable alternative, leaving offenders with little choice but to dispose of waste illegally. This crisis places a heavy financial burden on councils and taxpayers who bear the cost of clean up, transportation, and prosecution.
        </p>
        <h3>Key facts:</h3>
        <ul>
          <li>Cost to councils: Councils are forced to divert substantial resources to collect and dispose of fly-tipped waste at local waste sites.</li>
          <li>Legal repercussions: Offenders face criminal records, fines, and even imprisonment—outcomes that often fail to deter the behaviour and exacerbate social and financial inequalities.</li>
          <li>Broader impact: Fly-tipping poses risks such as road accidents caused by debris, health hazards from decomposing waste, and environmental degradation.</li>
        </ul>
        <p>
          Costing the Taxpayer: Councils in England spend around £1 billion annually dealing with littering and fly-tipping, with the direct cost of clearing fly-tipping incidents estimated at nearly £392 million per year.
        </p>
      </div>

      {/* Right Column */}
      <div className="image-column">
        <img
          src={HomeImage}
          alt="Fly-tipping issue"
          className="responsive-image"
        />
      </div>
    </div>
  
          <h2>The Solution: SkipSpace</h2>
          <p style={{ paddingBottom: "25px" }}>
            SkipSpace offers a transformative approach to combat fly-tipping. Our innovative mobile app provides councils and offenders with a proactive, efficient, and sustainable alternative to illegal dumping. By leveraging technology, SkipSpace bridges the gap between waste generators and responsible disposal methods.
          </p>
          <h3>How SkipSpace Works:</h3>
          <h4>For Councils:</h4>
          <ul>
            <li>SkipSpace partners with councils to identify existing waste sites or establish dedicated trial sites.</li>
            <li>Councils can monitor, manage, and streamline waste disposal through the app, reducing administrative overheads and prosecution costs.</li>
          </ul>
          <h4>For Offenders:</h4>
          <ul>
            <li>Offenders are offered a clear alternative to illegal dumping, such as free or heavily discounted waste disposal.</li>
            <li>The app provides easy navigation to designated sites and offers incentives for compliance, turning potential offenders into responsible citizens.</li>
          </ul>
          
          <h2 style={{ paddingTop: "25px" }}>A Call to Action: Partnering with Councils</h2>
          <p>
            To prove the concept, SkipSpace seeks a council willing to pilot the scheme. Together, we can:
          </p>
          <ul>
            <li>Demonstrate the potential for reduced fly-tipping incidents.</li>
            <li>Showcase cost savings in waste management and legal proceedings.</li>
            <li>Build a scalable model that can be adopted nationwide.</li>
          </ul>
          
          <h2 style={{ paddingTop: "25px" }}>The Vision: Preventing and Eradicating Fly-Tipping</h2>
          <p>
            By engaging councils in the SkipSpace initiative, we aim to:
          </p>
          <ul>
            <li>Mitigate the environmental and health risks associated with fly-tipping.</li>
            <li>Alleviate the financial burden on taxpayers and councils.</li>
            <li>Foster a culture of responsible waste disposal through education and incentives.</li>
          </ul>
          
          <h2 style={{ paddingTop: "25px" }}>The Need for Action</h2>
          <p>
            Fly-tipping is not just a nuisance—it’s a public safety, health, and environmental crisis. Road accidents caused by illegally dumped debris, toxic waste seeping into local ecosystems, and the visual blight on our landscapes are just some of the immediate dangers. Moreover, the financial cost to taxpayers runs into millions annually, funds that could be better spent on community development.
          </p>
          
          <h2 style={{ paddingTop: "25px" }}>A Collaborative Approach</h2>
          <p>
            SkipSpace offers a unique opportunity for councils to take a leading role in addressing this issue. By working together, we can:
          </p>
          <ul>
            <li>Create a safer and cleaner environment for residents.</li>
            <li>Build trust and cooperation between councils and communities.</li>
            <li>Establish a replicable model that positions the council as a pioneer in waste management innovation.</li>
          </ul>
          
          <Link to="/our-impact">
            <p className="text-underline" style={{ textDecoration: "underline", textUnderlinePosition: "under", textDecorationSkipInk: "none", fontWeight: "bold", color: "#000000", paddingTop: "25px" }}>
              Our Impact <FontAwesomeIcon icon={faArrowRight} />
            </p>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Home;

   /* Rolling Banner 
<div className="rolling-banner">
  <div className="rolling-content">
    <span>
      <p className="rolling-text">
        SKIPSPACE HAS BEEN SHORTLISTED AS A FINALIST IN THE ONLINE BUSINESS OF THE YEAR CATEGORY AT THE LCC AWARDS!
      </p>
    </span>
    <span>
      <p className="rolling-text">
        SKIPSPACE HAS BEEN SHORTLISTED AS A FINALIST IN THE ONLINE BUSINESS OF THE YEAR CATEGORY AT THE LCC AWARDS!
      </p>
    </span>
 
    <span>
      <p className="rolling-text">
        SKIPSPACE HAS BEEN SHORTLISTED AS A FINALIST IN THE ONLINE BUSINESS OF THE YEAR CATEGORY AT THE LCC AWARDS!
      </p>
    </span>
    <span>
      <p className="rolling-text">
        SKIPSPACE HAS BEEN SHORTLISTED AS A FINALIST IN THE ONLINE BUSINESS OF THE YEAR CATEGORY AT THE LCC AWARDS!
      </p>
    </span>
    <span>
      <p className="rolling-text">
        SKIPSPACE HAS BEEN SHORTLISTED AS A FINALIST IN THE ONLINE BUSINESS OF THE YEAR CATEGORY AT THE LCC AWARDS!
      </p>
    </span>
    <span>
      <p className="rolling-text">
        SKIPSPACE HAS BEEN SHORTLISTED AS A FINALIST IN THE ONLINE BUSINESS OF THE YEAR CATEGORY AT THE LCC AWARDS!
      </p>
    </span>
    <span>
      <p className="rolling-text">
        SKIPSPACE HAS BEEN SHORTLISTED AS A FINALIST IN THE ONLINE BUSINESS OF THE YEAR CATEGORY AT THE LCC AWARDS!
      </p>
    </span>
  </div>
</div>
*/