import React from "react";
import "../App.css";
import Header from "../components/Header";
import AwardsLogo from "../images/awards-logo.jpg"; // Ensure the correct path
import TalkTVVideo from "../videos/talktv.mp4"; // Ensure the correct path

function Announcements() {
  return (
    <div>
      <Header />
      <div className="custom-container">

        {/* Awards Section - Image Left, Text Right */}
        <div className="announcement-section awards">
          <div className="column left">
          <h2>SkipSpace: Finalist in the Online Business of the Year Category</h2>
            <p>
              We are thrilled to announce that SkipSpace has been shortlisted as a finalist in the 
              <strong> Online Business of the Year</strong> category at the London Chamber of Commerce and Industry SME London Business Awards 2025!
            </p>
          </div>
          <div className="column right">
          <img src={AwardsLogo} alt="Awards Logo" className="awards-logo" />
          </div>
        </div>

        {/* Divider */}
        <hr className="section-divider" />

        {/* TalkTV Video Section - Text Left, Video Right */}
        <div className="announcement-section talktv">
          <div className="column left">
            <h2>SkipSpace Featured on TalkTV</h2>
            <p>
              Watch our recent feature on TalkTV, where we discuss the impact of fly-tipping and 
              how SkipSpace is looking to make a difference.
            </p>
          </div>
          <div className="column right">
            <div className="talktv-video-container">
              <video width="100%" height="auto" controls>
                <source src={TalkTVVideo} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Announcements;
